<template>
  <div id="portfolio-contents-wrapper">
    <v-row>
      <v-col cols="12" sm="4" v-for="(item, idx) in items" :key="idx">
        <!-- <v-img
          class="portfolio-thumbnail"
          :src="item.img"
          @click="showSlideShow(idx)"
        ></v-img> -->
        <img
          :src="item.img"
          class="portfolio-cover-image"
          @click="showSlideShow(idx)"
        />
      </v-col>
    </v-row>
    <v-dialog content-class="portfolio-slide-show" v-model="showDialog">
      <v-carousel v-model="activeIdx" height="auto" hide-delimiters>
        <v-carousel-item v-for="(item, idx) in items" :key="idx">
          <!-- <v-img
            :width="$vuetify.breakpoint.mdAndUp ? '80vh' : '50vh'"
            :height="$vuetify.breakpoint.mdAndUp ? '80vh' : '50vh'"
            :src="item.imgLarge"
          ></v-img> -->
          <img :src="item.imgLarge" class="portfolio-detail-image" />
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  methods: {
    showSlideShow(idx) {
      this.activeIdx = idx;
      setTimeout(() => (this.showDialog = true), 200); // small delay to make transition animation not visible
    },
  },
  data() {
    return {
      showDialog: false,
      activeIdx: 0,
    };
  },
};
</script>

<style lang="scss">
#portfolio-contents-wrapper {
  margin-top: 24px;
  margin-bottom: 36px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 32px;
    margin-bottom: 48px;
  }
  .portfolio-thumbnail {
    cursor: pointer;
  }
}
.portfolio-slide-show {
  width: auto !important;
}
.portfolio-contents {
  padding: 17px !important;
}
.portfolio-cover-image {
  cursor: pointer;
  width: 100%;
  height: auto;
}
.portfolio-detail-image {
  width: 50vh;
  height: 50vh;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 80vh;
    height: 80vh;
  }
}
</style>