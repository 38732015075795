<template>
  <header>
    <div class="header-inner">
      <v-container>
        <v-row :align="$vuetify.breakpoint.mdAndUp? undefined: 'center'" 
          :no-gutters="!$vuetify.breakpoint.mdAndUp"
        >
          <v-col cols="3" sm="2" class="flex-grow-0">
            <v-img
              v-if="!scrolled"
              src="/img/logo.png"
              contain
              height="100"
            ></v-img>
          </v-col>
          <v-col
            cols="6"
            :sm="scrolled && $vuetify.breakpoint.mdAndUp ? 12 : 8"
            class="menu-wrapper"
          >
            <v-img
              v-if="!scrolled"
              src="/img/title.png"
              class="mx-auto"
              :width="$vuetify.breakpoint.mdAndUp ? 350 : 250"
              contain
            ></v-img>
            <v-row id="desktop_menu" v-if="$vuetify.breakpoint.mdAndUp" :class="{'mt-8': !scrolled}">
              <v-col v-for="(item, i) in items" :key="i">
                <a :href="item.url"
                  :class="{active: item.url === activeUrl}"
            >
                  {{ item.title }}
                </a>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="!scrolled || !$vuetify.breakpoint.mdAndUp"
            cols="3"
            sm="2"
            class="menu-toggle-wrapper"
          >
            <v-menu v-if="!$vuetify.breakpoint.mdAndUp"
              bottom
              left
              :offset-y="true"
              content-class="mobile-menu"
            >
              <template v-slot:activator="{ on }">
                <img class="menu-toggle" v-on="on" src="/img/menu.png">
              </template>

              <v-list>
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-title>
                    <a :href="item.url">{{ item.title }}</a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </header>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import { getHeaderHeight } from '@/services/ui';

export default {
  data() {
    return {
      scrolled: false,
      items: [
        { title: "ABOUT", url: "#animation" },
        { title: "PORTFOLIO", url: "#portfolio-wrapper" },
        { title: "CONTACT", url: "#contact-form" },
      ],
      activeUrl: null,
    }
  },
  mounted() {
    this.handleScroll = _.debounce(this._handleScroll, 50);
    const $window = $(window);
    $window.on('scroll', this.handleScroll);
    $window.on('resize', this.handleScroll);
  },
  destroyed() {
    if (this.handleScroll) {
      const $window = $(window);
      $window.off('scroll', this.handleScroll);
      $window.off('resize', this.handleScroll);
      this.handleScroll = undefined;
    }
  },
  methods: {
    _handleScroll() {
      this.scrolled = $(window).scrollTop() > 50;
      this.setScrolled(this.scrolled);

      this.activeUrl = null;
      for (let item of this.items) {
        const itemRect = $(item.url)[0].getBoundingClientRect();
        if (itemRect.top < getHeaderHeight() + 50 && itemRect.bottom > 0) {
          this.activeUrl = item.url
        }
      }
    },
    setScrolled(scrolled) {
      $('body').toggleClass('scrolled', scrolled);
    }
  }
};
</script>

<style lang="scss">
@import "../styles/mixins";

header {
  .header-inner {
    border-top: 1px solid #cfcaf3;
    border-bottom: 3px solid #cfcaf3;
    > .container > .row {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-left: -($container-padding-x/2);
        margin-right: -($container-padding-x/2);
      }
      > div {
        padding-top: 0;
        padding-bottom: 0;
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          padding-left: $container-padding-x/2;
          padding-right: $container-padding-x/2;
        }
      }
    }
    .menu-wrapper {
      text-align: center;
      #desktop_menu {
        > .col {
          padding-top: 0;
          padding-bottom: 0
        }
        a {
          @include contact-link();
          font-weight: normal;
          &:after {
            opacity: 0;
          }
          &:hover {
            color: #ffcc69;
            &:after {
              opacity: 1;
              background-color: #ffcc69;
            }
          }
          &.active {
            color: #cfaff6;
            &:after { background-color: #cfaff6; }
          }
        }
      }
    }
    .menu-toggle-wrapper {
      display: flex;
      justify-content: center;
      .menu-toggle {
        width: 36px;
        cursor: pointer;
      }
    }
  }
  .scrolled & {
    .header-inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(#fff, .6);
      z-index: 1000;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        left: 12px;
        right: 12px;
      }
    }
  }
}
.mobile-menu {
  .v-list.v-sheet {
    padding-right: 64px;
    background-color: #cfcaf3;
    a {
      color: #fff;
    }
  }
}
</style>