<template>
    <div id="herotext-ticker-wrapper">
        <marquee-text :duration="25">
            &nbsp; Welcome to studio Chanpei! Wanna see more of my work? Drop me a message below or follow me on Instagram &lt;3
        </marquee-text>
    </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
export default {
    name: 'HeroTextTicker',
    components: {
        MarqueeText,
    },
};
</script>

<style lang="scss" scoped>
#herotext-ticker-wrapper {
    font-family: 'Chango';
    font-size: 60px;
    color: #FF6600;
    padding-top: 10px;
    padding-bottom: 20px;
}
.marquee-text-text {
    margin-right: 20px;
}
</style>
