<template>
  <div id="contact-form">
    <div class="contact-heading-wrapper">
      <v-container>
        <SectionTitle
          title="PLEASE SAY HELLO"
          alternativeTitle="Contact"
          :backgroundImage="`url('/img/brushstroke_contact.png')`"
        />
        <p class="white--text">
          Websites are lovely, but talking is better. Connect with me, or sign up
          for non-spammy updates below. I can’t wait to meet you!
        </p>
      </v-container>
    </div>
    <div class="typeform-wrapper">
      <v-container>
        <div
          class="typeform-widget"
          data-url="https://form.typeform.com/to/seY8aIWJ?typeform-medium=embed-snippet"
          style="width: 100%; height: 500px"
        ></div>
      </v-container>
    </div>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  mounted() {
    (function() {
      var js,
        q,
        d = document,
        gi = d.getElementById,
        ce = d.createElement,
        gt = d.getElementsByTagName,
        id = "typef_orm",
        b = "https://embed.typeform.com/";
      if (!gi.call(d, id)) {
        js = ce.call(d, "script");
        js.id = id;
        js.src = b + "embed.js";
        q = gt.call(d, "script")[0];
        q.parentNode.insertBefore(js, q);
      }
    })();
  },
};
</script>

<style lang="scss" scoped>
#contact-form {
  .contact-heading-wrapper {
    background-color: #cfcaf3;
    padding-top: 32px;
    p {
      margin-top: 24px;
      font-size: 150%;
    }
  }
  .typeform-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
    background-image: url("/img/1264w/contact-bg.png");
    background-repeat: repeat;
    background-attachment: fixed;
    // @media #{map-get($display-breakpoints, 'sm-and-up')} {
    //   background-image: url("/img/1264w/contact-bg.png");
    // }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      background-image: url("/img/1920w/contact-bg.png");
      padding-top: 64px;
      padding-bottom: 96px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      background-image: url("/img/2560w/contact-bg.png");
    }
    // @media #{map-get($display-breakpoints, 'xl-only')} {
    //   background-image: url("/img/2560w/contact-bg.png");
    // }
    @media only screen and (min-width: 2560px) {
      background-image: url("/img/3840w/contact-bg.png");
    }
  }
}
</style>
