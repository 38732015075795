<template>
  <div id="animation">
    <v-lazy v-model="show">
      <transition-group>
        <v-container key="animation-section-title">
          <SectionTitle
            title="THAT VICKIE CHAN"
            alternativeTitle="Vickie Chan Hong Kong Designer"
            textColor="#ffcc66"
            :backgroundImage="`url('/img/brushstroke_aboutme.png')`"
          />
        </v-container>
        <div class="animation-content-wrapper" key="animation-circles">
          <v-container>
            <div id="animation-content" ref="animation_content">
              <div class="canvas-wrapper">
                <Circles />
              </div>
              <img
                class="jungle-image jungle-image-left"
                :class="{ 'animation-end': jungleAnimationEnd }"
                :src="require('../assets/jungle_left.webp')"
                ref="jungle_left"
              />
              <img
                class="jungle-image jungle-image-right"
                :class="{ 'animation-end': jungleAnimationEnd }"
                :src="require('../assets/jungle_right.webp')"
                ref="jungle_right"
              />
              <img
                class="animation-image animation-image-left-bird"
                :src="require('../assets/left_bird.png')"
                ref="left_bird"
              />
              <img
                class="animation-image animation-image-central-bird"
                :src="require('../assets/central_bird.png')"
                ref="central_bird"
              />
              <img
                class="animation-image animation-image-cloud-right"
                :src="require('../assets/cloud_right.webp')"
                ref="cloud_right"
              />
              <img
                class="animation-image animation-image-cloud-left"
                :src="require('../assets/cloud_left.webp')"
                ref="cloud_left"
              />
              <img
                class="animation-image animation-image-chop-long"
                :src="require('../assets/chop_long.webp')"
                ref="chop_long"
              />
              <img
                class="animation-image animation-image-cat"
                :src="require('../assets/cat.png')"
                ref="cat"
              />
              <img
                class="animation-image animation-image-junk"
                :src="require('../assets/junk.webp')"
                ref="junk"
              />
            </div>
          </v-container>
        </div>
        <v-container key="animation-contact">
          <div class="contact mt-12">
            For a full bio, or more information please
            <a href="mailto:Vickie@studiochanpei.com">email me</a>.
          </div>
        </v-container>
      </transition-group>
    </v-lazy>
  </div>
</template>

<script>
import { gsap, TimelineLite } from "gsap";
import $ from "jquery";
import Circles from "./Circles";
import SectionTitle from "./SectionTitle";

export default {
  components: {
    SectionTitle,
    Circles,
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$nextTick(this.onShow);
      }
    },
    imageNotLoaded(newVal) {
      if (!newVal) {
        this.startAnimation();
      }
    },
  },
  data() {
    return {
      imageNotLoaded: 0,
      jungleAnimationEnd: false,
      show: false,
    };
  },
  methods: {
    onShow() {
      const $imgs = $(this.$el).find("img");
      this.imageNotLoaded += $imgs.length;
      $imgs.one("load", this.onImageLoaded);
    },
    startAnimation() {
      const jungle_duration = 3;
      gsap.to(this.$refs.jungle_left, {
        duration: jungle_duration,
        left: this.$vuetify.breakpoint.mdAndUp ? "0" : "-25%",
        onComplete: this.afterJungleOpen,
      });
      if (!this.$vuetify.breakpoint.mdAndUp) {
        gsap.to(this.$refs.jungle_left, {
          duration: jungle_duration / 2,
          delay: jungle_duration,
          opacity: 0,
        });
      }
      gsap.to(this.$refs.jungle_right, {
        duration: jungle_duration,
        right: this.$vuetify.breakpoint.mdAndUp ? "0" : "-25%",
      });
      if (!this.$vuetify.breakpoint.mdAndUp) {
        gsap.to(this.$refs.jungle_right, {
          duration: jungle_duration / 2,
          delay: jungle_duration,
          opacity: 0,
        });
      }
    },
    afterJungleOpen() {
      this.jungleAnimationEnd = true;
      let left_bird_animate = new TimelineLite({ repeat: -1 });
      let left_bird_duration = 2;
      left_bird_animate
        .to(this.$refs.left_bird, left_bird_duration, {
          top: "4%",
        })
        .to(this.$refs.left_bird, left_bird_duration, {
          top: "0",
        });
      this.junkRandomPausePlay(left_bird_animate, left_bird_duration);

      this.junkAnimation();
      let central_bird_animate = new TimelineLite({ repeat: -1 });
      let central_bird_duration = 2;
      central_bird_animate
        .to(this.$refs.central_bird, central_bird_duration, {
          top: "46%",
        })
        .to(this.$refs.central_bird, central_bird_duration, {
          top: "50%",
        });
      this.junkRandomPausePlay(central_bird_animate, central_bird_duration);
      // central_bird_animate.to(this.$refs.central_bird, central_bird_duration, {
      //   top: "50%",
      //   left: "42%",
      //   delay: 0.1,
      // });
    },
    junkAnimation() {
      let junk_animate = new TimelineLite({ repeat: -1 });
      let junk_move_duration = 4;
      junk_animate
        .to(this.$refs.junk, junk_move_duration, {
          left: "35%",
        })
        .to(this.$refs.junk, 0.5, {
          transform: "rotateY(180deg)",
        })
        .to(this.$refs.junk, junk_move_duration, {
          left: "0",
        })
        .to(this.$refs.junk, 0.5, {
          transform: "rotateY(0deg)",
        });
      this.junkRandomPausePlay(junk_animate, junk_move_duration);
    },
    junkRandomPausePlay(animation, total) {
      let pauseRan = Math.floor(Math.random() * total + 1) * 1000;
      setTimeout(() => {
        animation.pause();
        let playRan = Math.floor(Math.random() * total + 1) * 1000;
        setTimeout(() => {
          animation.play();
          this.junkRandomPausePlay(animation, total);
        }, playRan);
      }, pauseRan);
    },
    onImageLoaded() {
      this.imageNotLoaded--;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/mixins";
/* 
 * Variables
*/
$ferry-width: 21%;
$record-width: 18%;
$marmite-width: 20%;
$vehicle-width: 24%;
$left-bird-width: 7%;
$central-bird-width: 10%;
$cloud-right-width: 10%;
$cloud-left-width: 8%;
$chop-long-width: 5%;
$cat-width: 24%;
$junk-width: 15%;
#animation {
  padding-top: 32px;
  padding-bottom: 32px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .animation-content-wrapper {
    background-image: url("/img/animation-bg.png");
    #animation-content {
      position: relative;
      width: 100%;
      margin-top: 24px;
      .canvas-wrapper {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
      .jungle-image {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 100;
        &.jungle-image-left {
          left: 50%;
          transform: translateX(-50%);
        }
        &.jungle-image-right {
          right: 50%;
          transform: translateX(50%);
        }
        &.animation-end {
          @media #{map-get($display-breakpoints, 'sm-and-down')} {
            opacity: 0;
          }
        }
      }
      .animation-image {
        position: absolute;

        &.animation-image-left-bird {
          width: $left-bird-width;
          top: 0;
          left: 14%;
        }
        &.animation-image-central-bird {
          width: $central-bird-width;
          top: 50%;
          left: 42%;
          bottom: $junk-width;
          z-index: 1;
          transform: translateY(-50%);
        }
        &.animation-image-cloud-right {
          width: $cloud-right-width;
          top: 0;
          right: 12%;
        }
        &.animation-image-cloud-left {
          width: $cloud-left-width;
          top: 20%;
          left: 15%;
          @media #{map-get($display-breakpoints, 'md-and-up')} {
            left: 18%;
          }
        }
        &.animation-image-chop-long {
          width: $chop-long-width;
          right: 12%;
          bottom: 10%;
          transform: translateY(-50%);
        }
        &.animation-image-cat {
          width: $cat-width;
          right: 0;
          bottom: 0;
        }
        &.animation-image-junk {
          width: $junk-width;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
  .contact {
    font-size: 22px;
    color: #57585a;
    a {
      @include contact-link();
    }
  }
}
</style>
