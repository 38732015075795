<template>
  <div id="portfolio-wrapper">
    <div class="portfolio-head">
      <v-container>
        <SectionTitle
          title="THE PORTFOLIO"
          alternativeTitle="Print, pattern and illustration portfolio"
          :backgroundImage="`url('/img/brushstroke_portfolio.png')`"
        />
        <p>
          If you would like to see or know more, please
          <a href="mailto:Vickie@studiochanpei.com">contact me</a> for a
          portfolio or bio.
        </p>
      </v-container>
    </div>
    <v-container>
      <portfolio-contents :items="items" />
    </v-container>
  </div>
</template>

<script>
import PortfolioContents from "@/components/PortfolioContents";
import SectionTitle from "./SectionTitle";

export default {
  components: {
    PortfolioContents,
    SectionTitle,
  },
  data: () => ({
    items: [
      {
        img: "/img/portfolio/1-SeedPacket2201_ChanNimJingStrawberry-Lo-500px.webp",
        imgLarge:
          "/img/portfolio/2-SeedPacket2201_ChanNimJingStrawberry-Lo-1500px.webp",
      },
  {
        img: "/img/portfolio/3-SeedPacket2201_ChanNimJingLohBak-Lo-500px.webp",
        imgLarge:
          "/img/portfolio/4-SeedPacket2201_ChanNimJingLohBak-Lo-1500px.webp",
      },
  {
        img: "/img/portfolio/5-SeedPacket2201_ChanNimJingTongHo-500px.webp",
        imgLarge:
          "/img/portfolio/6-SeedPacket2201_ChanNimJingTongHo-1500px.webp",
      },
      {
        img:
          "/img/portfolio/7-2021HKChristalBallbleGOOD-500px.webp",
        imgLarge:
          "/img/portfolio/8-2021HKChristalBallbleGOOD-1500px.webp",
      },


   {
        img:
          "/img/portfolio/11-2021HKNoodleDays6X4SANTA-500px.webp",
        imgLarge:
          "/img/portfolio/12-2021HKNoodleDays6X4SANTA-1500px.webp",
      },   {
        img:
          "/img/portfolio/13-2020HKXmasFromPeak2021Colour-500px.webp",
        imgLarge:
          "/img/portfolio/14-2020HKXmasFromPeak2021Colour-1500px.webp",
      },



      {
        img: "/img/portfolio/9-2021HKXmasBuntingSantaJunk6x4-500px.webp",
        imgLarge:
          "/img/portfolio/10-2021HKXmasBuntingSantaJunk6x4-1500px.webp",
      },
  {
        img: "/img/portfolio/17-2021HKXmasBuntingWoofYouMERRY6x4-500px.webp",
        imgLarge:
          "/img/portfolio/18-2021HKXmasBuntingWoofYouMERRY6x4-1500px.webp",
      },
  {
        img: "/img/portfolio/15-2021HKXmasBuntingJunk-500px.webp",
        imgLarge:
          "/img/portfolio/16-2021HKXmasBuntingJunk-1500px.webp",
      },



      {
        img: "/img/portfolio/19-202005MaMentoes-LycheeFanMosaic-500px.webp",
        imgLarge:
          "/img/portfolio/20-202005MaMentoes-LycheeFanMosaic-1500px.webp",
      },
      {
        img: "/img/portfolio/21-202005MaMentoes-WeekDays-500px.webp",
        imgLarge: "/img/portfolio/22-202005MaMentoes-WeekDays-1500px.webp",
      },
      {
        img: "/img/portfolio/23-202205VintageKitchenSunFlower-REPEAT-500px.webp",
        imgLarge: "/img/portfolio/24-202205VintageKitchenSunFlower-REPEAT-1500px.webp",
      },
      {
        img: "/img/portfolio/25-2021EightiesCasettes-500px.webp",
        imgLarge: "/img/portfolio/26-2021EightiesCasettes-1500px.webp",
      },
      {
        img:
          "/img/portfolio/27-2021EightiesRollerWay-500px.webp",
        imgLarge:
          "/img/portfolio/28-2021EightiesRollerWay-1500px.webp",
      },
      {
        img: "/img/portfolio/29-OhRainyDayTransport-500px.webp",
        imgLarge: "/img/portfolio/30-OhRainyDayTransport-1500px.webp",
      },
      {
        img: "/img/portfolio/31-202206_HisstericalSquigglesBRIGHT-01-500px.webp",
        imgLarge:
          "/img/portfolio/32-202206_HisstericalSquigglesBRIGHT-01-1500px.webp",
      },
      {
        img: "/img/portfolio/33-Birdcage_BirdStripeCoralBlue-500px.webp",
        imgLarge: "/img/portfolio/34-Birdcage_BirdStripeCoralBlue-1500px.webp",
      },
      {
        img: "/img/portfolio/35-2021_DapperDreamerCloudyChanceofLions-500px.webp",
        imgLarge:
          "/img/portfolio/36-2021_DapperDreamerCloudyChanceofLions-1500px.webp",
      },
      {
        img:
          "/img/portfolio/37-2022EightiesBoats-01-500px.webp",
        imgLarge:
          "/img/portfolio/38-2022EightiesBoats-01-1500px.webp",
      },
      {
        img: "/img/portfolio/39-2022CNYTigerBloomsGround-500px.webp",
        imgLarge:
          "/img/portfolio/40-2022CNYTigerBloomsGround-1500px.webp",
      },
      {
        img: "/img/portfolio/41-2022EightiesRainbows-01-500px.webp",
        imgLarge:
          "/img/portfolio/42-2022EightiesRainbows-01-1500px.webp",
      },
      {
        img: "/img/portfolio/43-2021YouPutColourInMyWorld-04-500px.webp",
        imgLarge: "/img/portfolio/44-2021YouPutColourInMyWorld-04-1500px.webp",
      },
  {
        img:
          "/img/portfolio/47-202203_RawrsomeComicSpeak-500px.webp",
        imgLarge:
          "/img/portfolio/48-202203_RawrsomeComicSpeak-1500px.webp",
      },
      {
        img: "/img/portfolio/45-2021AllTheSparkINeedB-500px.webp",
        imgLarge:
          "/img/portfolio/46-2021AllTheSparkINeedB-1500px.webp",
      },
    
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "../styles/mixins";

#portfolio-wrapper {
  .portfolio-head {
    background-color: #cfb1f4;
    color: #fff;
    padding-top: 32px;
    padding-bottom: 32px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    p {
      font-size: 22px;
      margin-top: 32px;
      margin-bottom: 0;
      a {
        @include contact-link();
      }
    }
  }
}
</style>
