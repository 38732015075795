<template>
  <footer>
    <v-container>
      <div class="to-top-wrapper">
        <img class="to-top" src="/img/to-top.png" @click="goToTop" />
      </div>
      <v-row align="center" no-gutters>
        <v-col cols="auto">
          <img
            class="footer-icon"
            :src="require('../assets/footer_logo.png')"
          />
        </v-col>
        <v-col>
          <div class="social-medias-wrapper">
            <div class="social-media-wrapper instagram">
              <div class="social-media">
                <a
                  target="_blank"
                  href="https://www.instagram.com/studiochanpei/"
                  ><i class="icofont-instagram"></i
                ></a>
              </div>
            </div>
            <div class="social-media-wrapper linktr">
              <div class="social-media">
                <a
                  target="_blank"
                  href="https://linktr.ee/chanpei"
                  ><i class="icofont-grocery"></i
                ></a>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="auto">
          <a href="mailto:Vickie@studiochanpei.com" class="contact">Contact</a>
        </v-col>
      </v-row>
      <div class="text-center mt-3 copyright" v-html="copyright"></div>
      <div class="text-center mt-3 copyright">
        Studio SSS LLC grants Vickie Chan permission to use their propriety font
        BACON on a self-promotional website.
      </div>
    </v-container>
  </footer>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      copyright: `© All content 2016-${new Date().getFullYear()} Vickie Chan | studio Chanpei`,
    };
  },
  methods: {
    goToTop() {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  font-family: "Rubik", sans-serif;
  background-color: #e3e1f1;
  padding-top: 16px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .to-top-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: -24px;
    .to-top {
      display: block;
      width: 48px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }
  }
  .footer-icon {
    width: 75px;
    @media (min-width: 425px) {
      width: 100px;
    }
    @media (min-width: 768px) {
      width: 125px;
    }
  }
  .social-medias-wrapper {
    display: flex;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
      width: 100%;
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 200px;
      width: 100%;
      flex-wrap: wrap;
    }
    .social-media-wrapper {
      order: 5;
      flex: 0 0 33.3%;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        flex: 0 0 75px;
      }

      .social-media {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #e3e1f1;
        font-size: 20px;
        padding: 6px;
        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          font-size: 30px;
        }
        @media #{map-get($display-breakpoints, 'md-and-up')} {
          padding: 12px;
        }
        &:before {
          content: "";
          width: 100%;
          padding-bottom: 100%;
          background: #fff;
          border-radius: 50%;
        }
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        a {
          color: unset;
        }
      }
      &.instagram {
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          order: 3;
        
          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
  .copyright {
    color: #ceb1f3;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 12px;
    }
  }
  .contact {
    font-family: "Comfortaa", cursive;
    padding: 8px;
    background: #ffcd69;
    border-radius: 2px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 20px;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 23px;
      padding: 12px 16px;
    }
  }
}
</style>
