<template>
  <div id="intro-wrapper">
    <v-container>
      <SectionTitle
        title="PRINT & PATTERN"
        alternativeTitle="Print and pattern design"
        :backgroundImage="`url('/img/brushstroke_print.png')`"
      />
      <p>
        <span>
          Vickie Chan is a British print and pattern designer and illustrator
          based in Hong Kong. Her work can be applied to paper, textiles and
          hardware for the home, stationery and fashion. She works by license or
          commission.
        </span>
      </p>
    </v-container>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle";

export default {
  components: {
    SectionTitle,
  },
};
</script>

<style lang="scss" scoped>
#intro-wrapper {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #cfcaf3;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  p {
    margin-top: 32px;
    margin-bottom: 0;
    color: #fff;
    > span {
      font-size: 24px;
    }
  }
}
</style>
