<template>
  <div ref='container' class="section-title"
    :style="{backgroundImage}"
  >
    <svg>
      <desc>{{ title }}</desc>
    </svg>
    <h1 class="d-none" v-if="alternativeTitle">{{ alternativeTitle }}</h1>
  </div>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import Snap from 'snapsvg';
import { 
  gsap, 
  TweenMax, 
  Power3, 
} from 'gsap';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: '#ffffff',
    },
    backgroundImage: {
      type: String,
      default: `url(${require('../assets/section-title-bg.png')})`
    },
    alternativeTitle: String,
  },
  mounted() {
    this._params = {
      fontFamily: 'Bacon',
      fontSize: '56px',
      fill: '#ffffff',
      text: this.title,
      color: this.textColor,
      letterSpacing: 5,
    };
    this.$container = $(this.$refs.container),
    this.$svg = this.$container.find('svg'),
    this._snap = Snap(this.$svg[0]),
    this._dashOffset = 1400,
    this._textMask = this._snap.text(0, 0, this._params.text).attr({
      'font-family': this._params.fontFamily,
      'font-size': this._params.fontSize,
      'letter-spacing': this._params.letterSpacing
    }),
    this._textStroke = this._snap.text(0, 0, this._params.text).attr({
      'font-family': this._params.fontFamily,
      'font-size': this._params.fontSize,
      fill: 'transparent',
        stroke: this._params.color,
      'stroke-width': 2,
      'stroke-dasharray': this._dashOffset,
      'stroke-dashoffset': this._dashOffset,
      'letter-spacing': this._params.letterSpacing
    }),
    this._animationData = {
        stroke: {
        dashOffset: this._dashOffset
      },
        },
    this.animationDuration = 5;
    this._isAnimationComplete = false,
      this._textMask.attr({
      fill: this._params.fill
      });

    this.show();
    gsap.ticker.add(this.update);

    this.resize = _.debounce(this._resize, 250);
    $(window).on("resize", this.resize).resize();
  },
  destroyed() {
    $(window).off('resize', this.resize);
    gsap.ticker.remove(this.update);
  },
  methods: {
    resizeContainer() {
      const height = this._textStroke.getBBox().height;
      if (!height) return;

      this.$container.height(height);
      this.$container.find('svg').height(height);

      const newY = height - height / 4;
      this._textMask.transform(`t0,${newY}`);
      this._textStroke.transform(`t0,${newY}`);
    },
    show() {
      TweenMax.to(this._animationData.stroke, this.animationDuration, {
        dashOffset: 0,
        ease: Power3.easeInOut,
        onComplete: () => {
          this._isAnimationComplete = true;
          }
      });
    },
    update() {
      if (!this._isAnimationComplete) {
        this._textStroke.node.style.strokeDashoffset = this._animationData.stroke.dashOffset
      }
    },
    _resize() {
      const containerWidth = this.$container.width();
      // brute force a best font size to fit into screen
      // FIXME: find a better way to calculate directly
      let fontSize = parseInt(this._params.fontSize);
      for (; fontSize >= 16; fontSize -= 4) {
        this._textStroke.attr({ 'font-size': `${fontSize}px`});
        const textWidth = this._textStroke.getBBox().width;
        if (textWidth < containerWidth) {
          break;
        }
      }

      this._textMask.attr({ 'font-size': `${fontSize}px`});
      setTimeout(this.resizeContainer, 200);
    },
  }
};
</script>


<style lang="scss" scoped>
.section-title {
  background-size: contain;
  svg {
    width: 100%;
  }
}
</style>
