<template>
  <div>
    <Header />
    <HeroTextTicker />
    <!-- <Hero /> -->
    <Intro />
    <Animation />
    <Portfolio />
    <contact-form />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import HeroTextTicker from '@/components/HeroTextTicker';
// import Hero from "@/components/Hero";
import Intro from "@/components/Intro";
import Animation from "@/components/Animation";
import Portfolio from "@/components/Portfolio";
import ContactForm from "@/components/Contact";
import Footer from "@/components/Footer";
import $ from 'jquery';
import { scrollTo } from '@/services/ui';

export default {
  name: 'Home',
  components: {
    Header,
    HeroTextTicker,
    // Hero,
    Intro,
    Animation,
    Portfolio,
    ContactForm,
    Footer,
  },
  watch: {
    '$route': 'handleHash'
  },
  mounted() {
    this.handleHash();
  },
  methods: {
    handleHash() {
      if(!this.$route.hash || this.$route.hash == '#') return;
      const elem = $(this.$route.hash)[0];
      if(elem) {
        scrollTo(elem.getBoundingClientRect().top);
      }
    },
  }
}
</script>
<style lang="scss">
footer {
  margin-top: 60px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 72px;
  }
}
body.scrolled {
  #hero-image-wrapper {
    margin-top: 128px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: 52px;
    }
  }
}
</style>