<template>
  <div class="circles">
    <div class="image-container">
      <div class="painting" ref="container"></div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
// import $ from "jquery";
import * as PIXI from "pixi.js";
import { Quad, TimelineMax } from "gsap";

PIXI.utils.skipHello();

const MAX_PARTICLE = 250;

export default {
  mounted() {
    this.container = this.$refs.container;
    this.resize();
  },
  data() {
    return {
      zoom: 1.1,
      particleCnt: 0,
      draw: true,
      u: {
        x: 0,
        y: 0,
      },
      resize: _.debounce(this._resize, 20),
    };
  },
  methods: {
    redraw() {
      this.clean();
      this.init();
    },
    init() {
      this.particleCnt = 0;
      this.app = new PIXI.Application({
        width: this.container.offsetWidth,
        height: this.container.offsetHeight,
        transparent: true,
        resolution: 2,
        autoDensity: true,
      });
      this.app.stage.interactive = true;
      this.container.appendChild(this.app.view);

      let opt = {
        scaleMode: PIXI.SCALE_MODES.NEAREST,
      };

      this.hiddenSprite = PIXI.Sprite.from("/img/circles-hidden2.webp", opt);
      this.hiddenSprite.anchor.set(0.5, 0.5),
        (this.hiddenSprite.width = this.app.screen.width),
        (this.hiddenSprite.height = this.app.screen.height),
        (this.hiddenSprite.x = this.app.screen.width / 2),
        (this.hiddenSprite.y = this.app.screen.height / 2);

      this.visibleSprite = PIXI.Sprite.from("/img/circles-visible2.webp", opt);
      this.visibleSprite.anchor.set(0.5, 0.5),
        (this.visibleSprite.width = this.app.screen.width),
        (this.visibleSprite.height = this.app.screen.height),
        (this.visibleSprite.x = this.app.screen.width / 2),
        (this.visibleSprite.y = this.app.screen.height / 2);

      this.app.stage.addChild(this.visibleSprite);

      window.addEventListener("resize", this.resize),
        this.app.ticker.add(this.render),
        this.container.addEventListener("mousemove", this.onMoving),
        this.container.addEventListener("touchmove", this.onMoving),
        this.container.addEventListener("touchstart", this.onMoving);
    },
    clean() {
      this.container.innerHTML = "";
      if (this.app) {
        this.app.ticker.remove(this.render);
        this.app.destroy();
        this.app = undefined;
      }
      if (this.visibleSprite) {
        this.visibleSprite.destroy();
        this.visibleSprite = undefined;
      }
      if (this.hiddenSprite) {
        this.hiddenSprite.destroy();
        this.hiddenSprite = undefined;
      }
      window.removeEventListener("resize", this.resize);
      this.container.removeEventListener("mousemove", this.onMoving);
      this.container.removeEventListener("touchmove", this.onMoving);
      this.container.removeEventListener("touchstart", this.onMoving);
      this.draw = true;
    },
    onMoving(e) {
      if (e.type == "touchstart" || e.type == "touchmove") {
        let mouseData = this.app.renderer.plugins.interaction.eventData.data
          .global;
        this.u.x = mouseData.x;
        this.u.y = mouseData.y;
      } else {
        this.u.x = e.offsetX;
        this.u.y = e.offsetY;
      }
      this.moving = true;
    },
    render() {
      if (this.moving && this.draw) {
        this.moving = false;
        this.addParticle(this.u.x, this.u.y);
      }
    },
    drawAll() {
      this.draw = false;
      this.app.stage.removeChildren();
      this.app.stage.addChild(this.hiddenSprite);
      this.particleCnt = 0;
    },
    _resize() {
      this.redraw();
      // if ($(window).width() < this.$vuetify.breakpoint.thresholds.sm) {
      //   this.drawAll();
      // }
    },
    addParticle(t, e) {
      if (this.particleCnt > MAX_PARTICLE) {
        return this.drawAll();
      }

      let opt = {
        scaleMode: PIXI.SCALE_MODES.NEAREST,
      };

      const hiddenSprite = PIXI.Sprite.from("/img/circles-hidden2.webp", opt);
      const brushSprite = PIXI.Sprite.from("/img/brush.png");

      hiddenSprite.anchor.set(0.5, 0.5),
        (hiddenSprite.width = this.app.screen.width),
        (hiddenSprite.height = this.app.screen.height),
        (hiddenSprite.x = this.app.screen.width / 2),
        (hiddenSprite.y = this.app.screen.height / 2);

      brushSprite.anchor.set(0.5);
      brushSprite.x = t;
      brushSprite.y = e;
      hiddenSprite.mask = brushSprite;

      this.app.stage.addChild(brushSprite, hiddenSprite);

      var n = new TimelineMax();
      n.fromTo(
        brushSprite.scale,
        0.3,
        {
          x: 0,
          y: 0,
        },
        {
          x: this.zoom,
          y: this.zoom,
          ease: Quad.easeOut,
        },
        0
      );

      this.particleCnt++;
    },
  },
};
</script>
<style lang="scss" scoped>
.circles {
  position: relative;
  .image-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .painting {
      width: 100%;
      height: 100%;
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 98%;
  }
}
</style>